.resume-card{
    background-color: white;
    width: 40vw;
    padding: 2vh 2vw;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.resume-image-link img{
    width: 100%;
    height: auto;
}

.resume-card h2{
    font-size: 30px;
    font-weight: 700;
    color: black;
}

.resume-card p{
    font-size: 20px;
    font-weight: 400;
    color: black;
}

.download-link {
    font-size: 20px;
    font-weight: 400;
    color: black;
    text-decoration: none;
    transition: all 0.2s;
    margin: 0 0 0 75%;
}

.download-link:hover{
    color: rgb(0, 81, 255);
    font-size: 22px;
    font-weight: 700;
}