.service-item {
    display: flex;
    flex-flow: column;
}

.service-item-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 2vh 0;
    gap: 2vw;
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background-color: rgba(9, 60, 226, 0.788); */
    background-color: rgba(226, 156, 25, 0.788);
    box-shadow: 0px 0px 15px 0px rgba(226, 156, 25, 0.788);
}

.service-item-header h3 {
    font-size: clamp(20px, 1.6666vw, 30px);
    color: white;
}

.service-item-body {
    margin: 0 0 0 22.5px;
    padding: 0 2vw 1vh 2vw;
    border-left: 5px solid rgba(25, 152, 226, 0.788);
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    gap: 1vw;
}

.service-item-body p {
    font-size: clamp(15px, 1.0416vw, 20px);
    color: white;
    text-align: justify;
    line-height: 1.5;
    align-self: center;
    width: 70%;
}

.service-item-image{
    width: 200px;
    height: 150px;
    border-radius: 80px;
    border: 5px solid rgba(25, 152, 226, 0.788);
    overflow: hidden;
}

.service-item-image img {
    width: 100%;
    height: 100%;
    transition: all 0.15s ease-in-out;
}

.service-item-image:hover img {
    transform: scale(1.3);
}