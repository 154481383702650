#resume-section {
    padding: 10vh 0;
    width: 80vw;
    margin: 0 auto;
}

#resume-section h1 {
    font-size: 40px;
    margin: 5vh 0;
    text-align: center;
}

.resume-container {
    display: flex;
    gap: 2vw;
}

@media only screen and (min-width: 360px) and (max-width: 430px) {
    .resume-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .resume-card {
        width: 80dvw !important;
        height: 330px;
        display: flex;
        flex-direction: column;
    }

    .resume-card h2 {
        font-size: clamp(25px, 6.94dvw, 30px) !important;
    }

    .resume-card p {
        font-size: clamp(22px, 6.11dvw, 25px) !important;
    }

    .download-link {
        display: block !important;
        width: 100% !important;
        text-align: center !important;
        margin: auto 0 !important;
        background-color: rgb(0, 81, 255) !important;
        color: white !important;
        font-size: clamp(20px, 5.56dvw, 22px) !important;
        border-radius: 5px !important;
        padding: 10px 0 !important;
    }
}