#contact-section {
    padding: 10vh 0;
}

#contact-article {
    width: 70vw;
    height: 80vh;
    margin: 5vh auto;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.contact-form-item {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2vw;
}

#contact-form-info {
    gap: 2vh;
}

#contact-form-info .code-terminal{
    margin: 25% 0 0 0;
}

#contact-form {
    gap: 2vh;
}

.form-input {
    display: flex;
    flex-flow: column;
}

.form-input input {
    min-width: fit-content;
    height: 3vh;
    padding: 1vh 1vw;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    font-size: 16px;
    font-weight: 400;
}

.form-input input:focus, .form-input textarea:focus {
    outline: none;
    border-color: rgba(0, 17, 255, 0.74) !important;
}

#messageInput {
    min-height: 10vh;
    padding: 1vh 1vw;
    max-width: 30vw;
    min-width: 30vw;
    max-height: 30vh;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    font-size: 17px !important;
    font-weight: 400 !important;
}

.form-input label {
    position: relative;
    left: 1vw;
    top: 3.6vh;
    max-width: fit-content;
    padding: 0 2px;
    z-index: 1;
    background-color: white;
    color: rgba(0, 0, 0, 0.514);
    transition: all 0.1s;
    cursor: text;
}

.input-label-active {
    left: 0.5vw !important;
    top: 1vh !important;
    color: rgba(0, 0, 0, 0.75) !important;
    font-weight: 600;
}

.input-label-focus {
    color: rgba(0, 17, 255, 0.74) !important;
}

.input-submit {
    width: 6vw;
    height: 6vh;
    margin: 2vh 0 2vh auto;
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 20px;
}

.submit-disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed !important;
}

.submit-enabled {
    background-color: #ffbb00;
}

.input-submit img{
    width: 25px;
    height: auto;
}

.alert {
    position: absolute;
    top: 85dvh;
    width: 100dvw;
    display: flex;
    justify-content: center;
}

.alert-text {
    font-size: 16px;
    text-align: center;
    width: 600px;
    border-radius: 5px;
    padding: 10px;
    transition: background-color 0.5s, color 0.5s;
}

.alert-success {
    background-color: rgba(14, 192, 88, 0.151);
    color: rgb(19, 114, 35);
}

.alert-error {
    background-color: rgba(255, 0, 0, 0.2);
    color: rgba(255, 0, 0, 1);
}

.alert-fade {
    background-color: rgba(42, 172, 96, 0);
    color: rgba(42, 172, 96, 0);
}

@media screen and (min-width: 320px) and (max-width: 430px) {
    #contact-article {
        width: 90dvw;
        height: fit-content;
        flex-direction: column;
    }

    .contact-form-item {
        width: 80dvw;
        height: fit-content;
        padding: 15px 5dvw;
    }

    #contact-form-info {
        gap: 5dvh;
    }

    #contact-form-info h1 {
        font-size: clamp(30px, 8.333dvw, 35px) !important;
    }

    #contact-form-info p {
        font-size: clamp(20px, 5.5556dvw, 25px) !important;
    }

    #contact-form-info .code-terminal{
        margin: 0;
    }

    #contact-form-info .snippet {
        height: 20px;
    }

    #contact-form-info .s-lg {
        width: clamp(120px, 33.33dvw, 150px);
    }

    #contact-form-info .s-md {
        width: clamp(50px, 16.67dvw, 75px);
    }

    #contact-form {
        gap: 20px;
    }

    .form-input input {
        min-width: 75dvw;
        height: 25px;
        padding: 8px 3dvw;
        font-size: clamp(16px, 4.44dvw, 20px) !important;
    }

    #messageInput {
        max-width: calc(78dvw - 1px);
        min-width: calc(78dvw - 1px);
        padding: 8px 3dvw;
        max-height: 250px;
        min-height: 83px;
        font-size: clamp(16px, 4.44dvw, 20px) !important;
    }

    .form-input label {
        left: 1dvw;
        top: 30px;
    }

    .input-submit {
        width: 80dvw;
        height: 50px;
        margin: 16px 0;
        font-size: 20px;
    }

    .input-submit img {
        width: 25px;
    }

    .alert {
        position: fixed;
        top: 90dvh;
    }

    .alert-text {
        font-size: 16px;
        text-align: center;
        width: 80dvw;
        border-radius: 5px;
        padding: 10px;
        transition: background-color 0.5s, color 0.5s;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1920px) {
    .input-submit {
        font-size: clamp(15px, 1.0416vw, 20px);
    }

    .input-submit img {
        width: clamp(20px, 1.0416vw, 25px);
    }
}