.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(1, 255, 77, 0.644);
    padding: 1vh 1vw;
    width: 20vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
}

.card:hover {
    box-shadow: 0px 0px 60px rgba(255, 255, 255, 0.945);
    scale: 1.01;
}

.card h2 {
    cursor: pointer;
}

.card img {
    justify-self: flex-start;
    width: auto;
    max-width: 110%;
    height: 60%;
}

.percentage-bar {
    width: 90%;
    height: 1vh;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 1vh 0;
}

.percentage-bar div {
    height: 100%;
    border-radius: 10px;
}

.card-details {
    background-color: white;
    position: absolute;
    z-index: 1;
    width: 20vw;
    height: 40vh;
    border-radius: 10px;
}

.card-details ul {
    margin: 5vh 0;
}

.card-details li {
    font-size: clamp(15px, 2vw, 20px);
    font-weight: 600;
    list-style: none;
    margin: 2vh auto;
    width: fit-content;
}