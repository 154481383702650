#landing-section {
    height: 100vh;
    background-image: url('../assets/landing-photo.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#landing-section h1 {
    font-size: clamp(50px, 3.5vw, 100px);
    color: white;
    text-align: center;
    margin: 0;
}

#landing-section h2 {
    font-size: clamp(35px, 3vw, 45px);
    color: white;
    text-align: center;
    margin: 0;
}

#about-section {
    padding: 10vh 0;
    height: 50vh;
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr;
    grid-template-rows: 0.5fr 3fr;
}

#about-section h2 {
    grid-column: 1 / span 3;
    grid-row: 1;
    font-size: clamp(30px, 2.5vw, 50px);
    color: black;
    align-self: flex-end;
}

#about-section p {
    padding: 0 2vw;
    grid-column: 2;
    grid-row: 2;
    font-size: clamp(15px, 1.0416vw, 20px);
    color: black;
    text-align: justify;
    align-self: center;
    line-height: 1.5;
}

#about-section div {
    grid-column: 3 / span 2;
    grid-row: 2;
    align-self: center;
}

#skills-section {
    padding: 10vh 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 4vh;
    background-color: rgba(25, 72, 226, 0.788);
}

#skills-section h2 {
    font-size: clamp(30px, 2.5vw, 50px);
    color: white;
    text-align: center;
}

#skills-section h3 {
    font-size: clamp(25px, 2vw, 45px);
    color: white;
    text-align: center;
    margin: 0;
}

.skills-article {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 4vw;
}

.skills-article h2 {
    color: black !important;
}

#services-section {
    padding: 10vh 0;
    height: fit-content;
    background-color: rgb(87, 87, 87);
}

#services-section h2{
    font-size: clamp(30px, 2.5vw, 50px);
    color: white;
    margin: 0 0 0 10vw;
}

#services-article {
    margin: 0 auto;
    width: 50vw;
}

.services-section-paragraph {
    font-size: clamp(15px, 1.0416vw, 20px);
    color: white;
    text-align: justify;
    line-height: 1.5;
    margin: 2vw 10vw;
}

.button-link {
    text-decoration: none;
    color: white;
    background-color: rgb(25, 152, 226);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: clamp(20px, 2vw, 25px);
    transition: all 0.15s ease-in-out;
    margin: 2vh 0 2vh 80vw;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
    #landing-section {
        height: 730px;
    }

    #landing-section h1 {
        font-size: clamp(40px, 11.11dvw, 50px);
    }

    #landing-section h2 {
        margin: 5px 0 0 0;
        font-size: clamp(25px, 6.94vw, 35px);
        font-weight: 400;
    }

    #about-section {
        height: fit-content;
        width: 90vw;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        margin: 0 5vw;
    }

    #about-section h2 {
        font-size: clamp(25px, 6.94vw, 35px);
        align-self: center;
    }

    #about-section p {
        font-size: clamp(15px, 4.16vw, 20px);
    }

    #about-snippet-div {
        height: 153.13px;
    }

    #about-section .s-lg {
        width: clamp(120px, 33.33vw, 150px);
    }

    #about-section .s-md {
        width: clamp(50px, 16.67vw, 75px);
    }

    #skills-title {
        font-size: 50px !important;
    }

    #skills-section h3 {
        font-size: 40px !important;
    }

    .skills-article {
        flex-flow: column;
        align-items: center;
        gap: 2vw;
    }

    .skills-article .card {
        width: 65vw;
        height: 250px;
    }

    .skills-article .card-details {
        width: 65vw;
        height: 250px;
    }

    .skills-article .card-details ul {
        margin: 25px 0;
    }

    .skills-article .card-details li {
        margin-top: 5px;
        font-size: clamp(15px, 4.16vw, 20px);
    }

    #services-article {
        width: 95vw;
        margin: 0 auto;
    }

    #services-article .service-item-body p {
        font-size: clamp(18px, 4.16vw, 20px);
        text-align: left;
    }

    .services-section-paragraph {
        font-size: clamp(18px, 5vw, 23px) !important;
    }

    .button-link {
        margin: 2vh 0 2vh 5vw;
    }

    #services-article .service-item-image {
        margin: auto 0;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1920px) {
    #about-section p {
        font-size: clamp(15px, 1.3vw, 20px);
    }

    #skills-section .card-details li {
        font-size: clamp(16px, 1.3vw, 20px);
    }

    #services-section-paragraph {
        font-size: clamp(15px, 1.3vw, 20px);
    }

    .service-item-header h3 {
        font-size: clamp(20px, 1.8vw, 30px);
    }
}