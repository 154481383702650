header {
    position: fixed;
    z-index: 100;
}

#navbar-desktop {
    height: 10vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease-in-out;
}

.navbar-background-desktop {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);
}

.navbar-background-item{
    color: rgb(0, 0, 0) !important;
}

.link-logo-navbar {
    width: fit-content;
    justify-self: center;
}

.logo {
    grid-column: 1;
}

#navbar-list-desktop {
    grid-column: 2;
    justify-self: center;
    display: flex;
    list-style: none;
    gap: 4vw;
}

#navbar-list-desktop li {
    font-size: clamp(15px, 1.0416vw, 20px); /* 15px to 20px */
}

#navbar-list-desktop a {
    text-decoration: none;
    color: white;
}

#navbar-toggle-mobile {
    display: none;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
    #navbar-desktop {
        height: 70px;
        display: flex;
        justify-content: space-between;
    }

    .link-logo-navbar {
        margin: 0 0 0 15px;
    }

    #navbar-toggle-mobile {
        display: block;
        margin: 0 15px 0 0;
        font-size: 40px;
        font-weight: bold;
    }

    #navbar-menu-mobile {
        top: 69.5px;
        position: absolute;
        background-color: white;
        width: 100dvw;
        height: fit-content;
        border-top: 0.5px solid rgba(0, 0, 0, 0.24);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.24);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.37);
        animation-name: menu-mobile;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }

    @keyframes menu-mobile {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }

    #navbar-list-mobile {
        width: 80%;
        margin: 15px auto;

    }

    #navbar-list-mobile li {
        list-style: none;
        width: 50%;
    }

    #navbar-list-mobile li:not(:first-child) {
        margin-top: 20px;
    }

    .navbar-list-item-mobile {
        display: block;
        text-decoration: none;
        color: black;
        width: 100%;
        font-size: 20px;
    }
    
    #navbar-list-desktop {
        display: none;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1920px) {
    #navbar-list-desktop li {
        font-size: clamp(15px, 1.2vw, 20px); /* 15px to 20px */
    }

    .logo {
        width: clamp(60px, 4.68vw, 69px);
        height: auto;
    }
}