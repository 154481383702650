.code-snippet {
    display: flex;
    gap: 0.5vw;
}

.snippet:nth-child(1) {
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.snippet:nth-child(2) {
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-delay: 0.16s;
    animation-fill-mode: forwards;
}

.snippet:nth-child(3) {
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-delay: 0.32s;
    animation-fill-mode: forwards;
}

.snippet:nth-child(4) {
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-delay: 0.48s;
    animation-fill-mode: forwards;
}

.snippet:nth-child(5) {
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-delay: 0.64s;
    animation-fill-mode: forwards;
}

/* h1 {
    visibility: hidden;
    font-size: 2.7vw;
    margin: 2vh 0px 2vh 0px;
    animation-name: snippet;
    animation-duration: 0.8s;
    animation-delay: 0.32s;
    animation-fill-mode: forwards;
} */

.snippet {
    visibility: hidden;
    height: 2.5vh;
    border-radius: 20px;
    margin: 5px 0px 5px 0px;
}

@keyframes snippet {
    0% {
        opacity: 0%;
        transform: translateX(100px);
    }
    100% {
        visibility: visible;
        opacity: 100%;
        transform: translateX(0px);
    }
}

.s-lg {
    width: 10vw;
    background-image: linear-gradient(to right, red, yellow);
}

.s-md {
    width: 5vw;
    background-image: linear-gradient(to right, rgb(99, 255, 99),rgb(104, 187, 255));
}

.s-sm {
    width: 24px;
    background-color: rgb(0, 0, 0);
}