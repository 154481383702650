footer {
    width: 100%;
    height: 60vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 4fr 1fr;
}

#footer-header {
    grid-column: 1 / span 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: 5vw;
    padding: 0 0 0 5.5vw;
}

#footer-header a {
    text-decoration: none;
    color: black;
}

#footer-header h2 {
    font-size: 35px;
    cursor: pointer;
}

#footer-nav {
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer-nav li {
    list-style: none;
    cursor: pointer;
}

#footer-nav li:not(:first-child) {
    margin: 5vh 0 0 0;
}

#footer-nav a {
    text-decoration: none;
    color: black;
    font-size: 20px;
}

#footer-socials {
    grid-column: 2;
    margin: auto 0 auto 10vw;
}

.socials-icons {
    display: flex;
    flex-flow: column;
    margin: 0 0 0 5vw;
    gap: 5vh;
}

.socials-icons a {
    width: fit-content;
}

#fb-svg {
    fill: #000000;
    transition: fill 0.1s ease-in-out;
}

#fb-svg:hover {
    fill: #1877F2;
}

#wa-svg-border {
    fill: #000000;
    transition: fill 0.1s ease-in-out;
}

#wa-svg-bg {
    fill: #ffffff;
    transition: fill 0.1s ease-in-out;
}

#wa-svg:hover #wa-svg-border {
    fill: #ffffff;
}

#wa-svg:hover #wa-svg-bg {
    fill: #25D366;
}

#ig-svg {
    fill: #000000;
    transition: fill 0.1s ease-in-out;
}

#ig-svg:hover {
    fill: #E4405F;
}

#li-svg {
    fill: #000000;
    transition: fill 0.1s ease-in-out;
    cursor: pointer;
}

#li-svg:hover {
    fill: #0A66C2;
}

#footer-footer {
    grid-column: 1 / span 2;
    grid-row: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
    footer {
        height: 584px;
    }

    #footer-header {
        padding: 0 0 0 5vw;
    }

    #footer-header h2 {
        font-size: clamp(30px, 8.33vw, 35px);
    }

    #footer-nav {
        margin: 0 0 0 5vw;
    }

    #footer-nav li:not(:first-child) {
        margin: 5vh 0 0 0;
    }

    #footer-nav a {
        font-size: clamp(25px, 6.94vw, 30px);
    }

    #footer-socials {
        margin: auto 0 auto 5vw;
    }

    .socials-icons {
        margin: 0 0 0 5vw;
    }

    .socials-icons a {
        width: fit-content;
    }
}