* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1, h2, h3, p {
  cursor: default;
}

.underline {
  text-decoration: underline;
  text-decoration-color: rgb(0, 103, 221);
  text-decoration-thickness: 3px;
}

.highlight {
  color: rgb(16, 128, 85);
  font-weight: bold;
}

.highlight-2 {
  color: rgb(0, 37, 245);
  font-weight: bold;
}
